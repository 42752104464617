import React from "react";
import { SEO, PageLayout, HeroElement } from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class InThePressPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"In The Press"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.masslive.com/food/2022/04/a-massachusetts-italian-sub-was-named-one-of-the-best-in-the-country-according-to-mashed-find-out-where-you-can-get-it.html"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/masslive.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.masslive.com/food/2022/04/a-massachusetts-italian-sub-was-named-one-of-the-best-in-the-country-according-to-mashed-find-out-where-you-can-get-it.html"
                    }
                  >
                    A Massachusetts Italian sub was named one of the best in the
                    country, according to Mashed: Find out where you can get it
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://boston.eater.com/2016/7/29/12325128/monicas-mercato-north-end-feature"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/Eater-Boston-Logo.jpeg"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://boston.eater.com/2016/7/29/12325128/monicas-mercato-north-end-feature"
                    }
                  >
                    Monica’s Mercato & Salumeria: Boston’s Home for Italian
                    Specialties and Incredible Sandwiches
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.mashed.com/745643/the-absolute-best-italian-subs-in-the-us/?utm_campaign=clip"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/mashed_logo_color_2.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.mashed.com/745643/the-absolute-best-italian-subs-in-the-us/"
                    }
                  >
                    The 15 Absolute Best Italian Subs In The US
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.bostonmagazine.com/best-of-boston-archive/2015/monicas-mercato-salumeria/"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/BostonMagazine_Logo_New-wMag.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.bostonmagazine.com/best-of-boston-archive/2015/monicas-mercato-salumeria/"
                    }
                  >
                    BEST OF BOSTON - 2015 Best Sandwiches
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.thefoodlens.com/boston/guides/best-italian-subs-in-boston/"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/foodlens.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.thefoodlens.com/boston/guides/best-italian-subs-in-boston/"
                    }
                  >
                    Where to Find the Best Italian Subs in Boston
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.wcvb.com/article/shaynas-favorites-feasting-on-spuckies-and-baked-goods-in-boston/42269999"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/WCVB_5_Boston_Logo.png?width=500"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.wcvb.com/article/shaynas-favorites-feasting-on-spuckies-and-baked-goods-in-boston/42269999"
                    }
                  >
                    Shayna's Favorites: Feasting on 'spuckies' and baked goods
                    in Boston
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://boston.eater.com/2022/12/20/23517740/best-boston-restaurant-meals-2022"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/Eater-Boston-Logo.jpeg?width=500"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://boston.eater.com/2022/12/20/23517740/best-boston-restaurant-meals-2022"
                    }
                  >
                    The Best Boston Restaurant Meals of 2022
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://pos.toasttab.com/blog/on-the-line/top-10-boston-foods"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/Toast_logo.png?width=500"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://pos.toasttab.com/blog/on-the-line/top-10-boston-foods"
                    }
                  >
                    Top 10 Boston Foods in 2023 (& Where to Find Them)
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.thefoodlens.com/boston/guides/sandwiches/?fbclid=PAAaYgqTSppqaZivIz8WAAsy8poasTckmEXq7tJZhwvgFupvCCX8Wxs-w3RdQ"
                  }
                >
                  <Image
                    size={"small"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicasmercato/foodlens_f83jC3q.png?width=500"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.thefoodlens.com/boston/guides/sandwiches/?fbclid=PAAaYgqTSppqaZivIz8WAAsy8poasTckmEXq7tJZhwvgFupvCCX8Wxs-w3RdQ"
                    }
                  >
                    Where to Order the Best Sandwiches in Boston
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <h3>
                  <a
                    target={"_blank"}
                    href={"https://www.youtube.com/watch?v=N-MebPp6als"}
                  >
                    The 12 Absolute Best Italian Subs In The US
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "In The Press" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
